<template>
    <v-dialog v-model="dialogVisible" max-width="800">
        <v-alert :value="true" :type="alertType" class="mb-0">
            <v-row>
                <v-col cols="10">
                    {{ content }}
                </v-col>
                <v-col cols="2">
                    <v-btn color="primary" text @click="closeDialog">閉じる</v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </v-dialog>
</template>

<script>
export default {
    props: {
        dialogVisible: Boolean,
        alertType: String,
        content: String,
    },
    methods: {
        closeDialog() {
            this.$emit('update:dialogVisible', false);
        },
    },
};
</script>
